import * as React from 'react';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';

import { Branding, ArchiveTable, PatchNotes } from 'julius-frontend-components';
import { searchArchive } from 'julius-frontend-store';

import * as notes from '../../notes/archive';
import Header from '../shared/header/header';

class Archive extends React.Component {
  state = {
    isNotesOpen: false,
    search: {
      query: '',
      tags: '',
    },
  };

  render() {
    const { archives, dispatch, styles } = this.props;
    const search = this.state.search;

    let update = true;
    if (localStorage.getItem('updateCount') && parseInt(localStorage.getItem('updateCount')) === notes.notes.length) {
      update = false;
    }

    return (
      <div className={styles.container}>
        <Branding
          update={update}
          text={'DialLog Lookup'}
          displayDash={true}
          onClick={() => {
            localStorage.setItem('updateCount', notes.notes.length);
            this.setState({ updateCount: notes.notes.length });
            this.setState({ isNotesOpen: !this.state.isNotesOpen });
          }}
        />
        <Header
          search={search}
          noDebounce={false}
          onFilterChange={value => {
            this.setState({ tags: value });
            dispatch(searchArchive(this.state.value, value));
          }}
          onSearchChange={value => {
            this.setState({ search: { query: value }, value });
            dispatch(searchArchive(value, this.state.tags));
          }}
        />
        <div className={styles.gridContainer}>
          {archives && archives.length > 0 ? (
            <ArchiveTable contacts={archives} />
          ) : (
            <p className={styles.searchResults}>
              {this.state.value && !archives.isLoading ? 'No Search Results Found' : ''}
            </p>
          )}
        </div>
        <PatchNotes
          isOpen={this.state.isNotesOpen}
          notes={notes.notes ? notes.notes : []}
          closeModal={() => {
            this.setState({ isNotesOpen: !this.state.isNotesOpen });
          }}
        />
      </div>
    );
  }
}

const withStyles = styled({
  gridContainer: {
    width: '100%',
    padding: '0 10px',
    margin: '0 auto',
    alignItems: 'center',
  },
  searchResults: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: 20,
  },
});

const withState = connect(state => {
  const { archives } = state.archive;
  return { archives };
});

export default withState(withStyles(Archive));
