import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { helpers, styled } from 'react-free-style';
import { parse } from 'querystring';
import { Helmet } from 'react-helmet';
import Notifications from 'react-notify-toast';
import { connect } from 'react-redux';
import * as colors from './styles/colors';

import { PrivateRoute, Login } from 'julius-frontend-components';
import { loadUser, redirectToAzure } from 'julius-frontend-store';

import gothamBold from './assets/fonts/gotham-bold.woff';
import gothamBook from './assets/fonts/gotham-book.woff';
import gothamMedium from './assets/fonts/gotham-medium.woff';

import Archive from './containers/archive';
import { defaultUrl, apiServerUrl } from './support/urls';

class Routes extends React.Component {
  componentWillMount() {
    const { dispatch } = this.props;
    const qs = parse(window.location.search.substr(1));
    if (qs.auth_status === 'success') {
      localStorage.setItem('uta.auth', qs.jwt);
      localStorage.setItem('uta.uid', qs.uid);
      window.location = window.location.href.split('?')[0];
    } else if (localStorage['uta.uid'] && localStorage['uta.auth']) {
      dispatch(loadUser(localStorage['uta.uid']));
    }
  }

  render() {
    const { styles, user } = this.props;

    const qs = parse(window.location.search.substr(1));

    const LoginComponent = () => {
      return <Login defaultUrl={defaultUrl} apiServerUrl={apiServerUrl} redirectToAzure={redirectToAzure} />;
    };

    if (qs.auth_status === 'success') {
      return null;
    }

    return (
      <Router>
        <div className={styles.container}>
          <Helmet>
            <title>ARCHIVE</title>
          </Helmet>
          <Notifications />
          <Route exact path="/login" component={LoginComponent} />
          <PrivateRoute exact path="/" component={Archive} user={user} />
        </div>
      </Router>
    );
  }
}

const withStyles = styled(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.background,
      fontFamily: 'Gotham, sans-serif',
    },
  },
  {
    css: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        backgroundColor: colors.background,
        fontFamily: 'Gotham, sans-serif',
        minWidth: 320,
        fontSize: 15,
        lineHeight: 1.3,
      },
    },
    rules: [
      [
        '@font-face',
        {
          fontFamily: 'Gotham',
          src: helpers.url(gothamMedium),
          fontWeight: 400,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'Gotham',
          src: helpers.url(gothamBold),
          fontWeight: 700,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'Gotham',
          src: helpers.url(gothamBook),
          fontWeight: 300,
          fontStyle: 'normal',
        },
      ],
    ],
  }
);

const withState = connect(state => {
  const { user } = state.user;
  return { user };
});

export default withStyles(withState(Routes));
