import * as React from 'react';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Avatar, OnClickOutside, Search } from 'julius-frontend-components';

import * as colors from '../../../styles/colors';
import { createShortcuts, filterInputEvent, keyboard, stringifyKey } from '../../../support/keyboard';

class Header extends React.Component {
  state = {
    firstLoad: true,
  };

  keyboardListener = filterInputEvent(
    createShortcuts({
      [stringifyKey('/')]: e => {
        e.preventDefault();

        return this.searchInput && this.searchInput.focus();
      },
    })
  );

  componentDidMount() {
    keyboard.addListener(this.keyboardListener);
  }

  componentWillUnmount() {
    keyboard.removeListener(this.keyboardListener);
  }

  render() {
    const { navigate, noDebounce, search, styles, user } = this.props;

    return (
      <div className={styles.headerContainer}>
        <div className={styles.leftColumn} />
        <div className={styles.searchColumn}>{this.searchBar(styles, noDebounce, search, navigate)}</div>
        <div className={styles.userColumn}>
          <div className={styles.header}>{this.userName(styles, user)}</div>
        </div>
      </div>
    );
  }

  userName(styles, user) {
    const signOutOnClick = () => {
      localStorage.removeItem('uta.auth');
      window.location = '/login';
    };

    const popoverBottom = (
      <Popover id="popover-positioned-bottom" positionTop="100" title={'Menu'}>
        <ul className={styles.menuList}>{popoverItem('Sign out', 'fa-sign-out', false, signOutOnClick, styles)}</ul>
      </Popover>
    );

    return (
      <div className={styles.user}>
        <div className={styles.userContent}>
          <div className={styles.imageColumn}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              delay={0}
              ref="trigger"
              onBlur={() => this.refs.trigger.hide()}
              overlay={popoverBottom}
            >
              <OnClickOutside onClickOutside={() => this.refs.trigger.hide()}>
                <div
                  className={styles.userImage}
                  style={{
                    backgroundImage: `url(${
                      user && user.personId && user.personId.profile_pic ? user.personId.profile_pic : Avatar
                    })`,
                  }}
                />
              </OnClickOutside>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  }

  searchBar(styles, noDebounce, search, navigate) {
    const { onSearchChange } = this.props;
    return (
      <div className={styles.search}>
        <Search
          noDebounce={noDebounce}
          value={search.query}
          onSubmit={query => {
            if (!query && onSearchChange) {
              onSearchChange(query);
              return;
            }
            if (navigate) {
              navigate({ ...search, query });
            }
          }}
          className={styles.searchContainer}
          inputClassName={styles.searchInput}
          onChange={value => {
            onSearchChange && onSearchChange(value);
          }}
          inputRef={searchInput => {
            this.searchInput = searchInput;
            if (this.state.firstLoad) {
              this.searchInput.focus();
              this.setState({ firstLoad: false });
            }
          }}
        />
      </div>
    );
  }
}

const withStyles = styled({
  headerContainer: {
    background: colors.contentBackground,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    justifyContent: 'center',
  },
  search: {
    width: '100%',
  },
  searchContainer: {
    margin: '10px',
  },
  searchInput: {
    '&&': {
      border: `1px solid ${colors.border}`,
      background: colors.background,
    },
  },
  menuList: {
    listStyle: 'none',
    width: '200%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: 10,
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
  menuIcon: {
    marginRight: 10,
  },

  userContent: {
    marginLeft: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  userImage: {
    borderRadius: '50%',
    height: 35,
    width: 35,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },

  imageColumn: {
    display: 'flex',
    flex: 0.4,
    marginRight: 10,
    marginTop: 3,
    flexDirection: 'column',
  },
  leftColumn: {
    display: 'flex',
    flex: 0.25,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'flex-start',
  },
  searchColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  userColumn: {
    display: 'flex',
    flex: 0.2,
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 5,
  },
});

const popoverItem = (label, iconClass, isDownloadingFlag, onClick, styles) => {
  const iconClassName = isDownloadingFlag
    ? `${styles.menuIcon} fa fa-fw fa-circle-o-notch fa-spin`
    : `${styles.menuIcon} fa fa-fw ${iconClass}`;

  return (
    <li className={styles.menuItem}>
      <a onClick={onClick}>
        <i className={iconClassName} aria-hidden="true" />
        {label}
      </a>
    </li>
  );
};

const withState = connect(state => {
  const { user } = state.user;

  return { user };
});

export default withState(withStyles(Header));
