// Import CSS before React components.
import 'bootstrap/dist/css/bootstrap.css';
import 'normalize.css/normalize.css';
import 'react-day-picker/lib/style.css';
import 'font-awesome/css/font-awesome.min.css';
import 'fixed-data-table-2/dist/fixed-data-table.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-custom-scroll/dist/customScroll.css';
import './styles/card.css';
import './styles/scroll.css';
import './styles/select.css';

import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import Routes from './routes';

import { createStoreWithOptions } from 'julius-frontend-store';

import { apiServerUrl } from './support/urls';
const store = createStoreWithOptions({ apiServerUrl });

function mount(App) {
  return render(
    <AppContainer>
      <Provider store={store}>
        <App />
      </Provider>
    </AppContainer>,
    document.getElementById('app')
  );
}

mount(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    mount(require('./routes').default);
  });
}

Array.prototype.moveToFront = function(callback) {
  var i = this.length;
  while (i--) {
    if (callback(this[i], i)) {
      this.unshift(this.splice(i, 1)[0]);
    }
  }
  return this;
};
